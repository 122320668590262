<template>
<section class="container-box">
  <div class="attach-file-container">
    <section v-if="!files?.length">
      <span >Nenhum arquivo anexado</span>
    </section>
    <section 
      v-else
      class="file-container"
    >
      <div 
        class="file-tag"
        v-for="(file, index) in files" :key="index"
      >
        <div class="data-file">
          <div class="data-file" @click="openDocsTab(file)">
              <Gallery v-if="Array.isArray(file)" class="file-icon"/>
              <Pdf v-else-if="file?.path?.includes('pdf')" class="file-icon" />
              <Png v-else-if="file?.path?.includes('png')" class="file-icon" />
              <Jpg v-else class="file-icon" />

              <p v-if="Array.isArray(file)">{{ file[0].group_name }}</p>
              <p class="file-name" v-else>{{ file.custom_name }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
</template>

<script>
import Pdf from '@/assets/icons/pdf.svg'
import Jpg from '@/assets/icons/jpg.svg'
import Png from '@/assets/icons/png.svg'
import Gallery from '@/assets/icons/gallery.svg'

export default {
  components: {  
    Pdf, 
    Jpg, 
    Png,
    Gallery,
  },
  props: {
    files: Array,
  },
  methods: {
    async getDocUrl(path){
      const loading = this.$loading.show()
      try {
        const res = await this.api.getDocTemporaryUrl(path)
        window.open(res.data, '_blank')
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async openDocsTab(docs) {
      if (Array.isArray(docs)) {
        docs.forEach(async doc => {
          await this.getDocUrl(doc.path)
        })
      } else {
        await this.getDocUrl(docs.path)
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.container-box {
  h4 {
      color: var(--dark-blue);
      font-weight: 600;
      font-size: 18px;
  }
}
.attach-file-container {
  width: 100%;
  display: flex;
  padding: 10px 0;
  flex-direction: row;
  justify-content: space-between;

  > section {
    overflow-y: hidden;
  }

  button {
    color: var(--blue-600);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    stroke: var(--blue-600);
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .fit-content {
    width: fit-content !important;
  }

  .file-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .file-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
      height: 35px;
      padding: 0 4px;
      background-color: var(--neutral-200);
      border-radius: 16px;
      cursor: pointer;

      .data-file {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .file-icon {
          width: 42px !important;
          height: 42px !important;
          transform: scale(0.5);
        }

        .file-name {
          max-width: 15ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 15px;
        }
      }

      .close-btn {
        background-color: var(--neutral-300);
        border-radius: 100%;

        :hover {
          fill: var(--neutral-600) !important;
        }
      }
      .close-icon {
        height: 20px;
        width: 20px;
        fill: var(--neutral-500);
      }

    }
  }

  .tags-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tag:nth-child(1) {
      margin-right: 5px;
    }
  }
  .tag {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    height: 35px;
    align-items: center;
    padding: 0 10px;
    border-radius: 16px;
    font-weight: 800;
    color: var(--type-active);
    background-color: var(--neutral-200);
  }

  .eye-container {
    background-color: var(--neutral-200);
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral-600);
    margin-right: 0px;

    svg {
      width: 22px;
      height: 22px;
      fill: var(--neutral-600);
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
