import { render, staticRenderFns } from "./AttachedFiles.vue?vue&type=template&id=1f93b65c&scoped=true"
import script from "./AttachedFiles.vue?vue&type=script&lang=js"
export * from "./AttachedFiles.vue?vue&type=script&lang=js"
import style0 from "./AttachedFiles.vue?vue&type=style&index=0&id=1f93b65c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f93b65c",
  null
  
)

export default component.exports